import React from "react";
import "./arvindStore.scss";
import StoreBox from "../StoreBox/StoreBox";
import { storeData } from "../../data/data";

const ArvindStore = () => {
  return (
    <>
      <div className="arvind_stores">
        <div className="my_container">
          <p className="desc" data-aos="fade-up">
            <span className="medium">The Arvind Store - LALBHAI GROUP</span>{" "}
            aims to create an enriching shopping experience through the
            convergence of Arvind’s strongest competencies under one roof.
            Arvind Store is a Complete Destination for Men's Apparel.
          </p>
          <StoreBox storeData={storeData} />
          <div className="content_wrapper" data-aos="fade-up">
            <h2 className="heading">THE ARVIND STORE: THE FABRICS</h2>
            <p className="description">
              Wide range of premium and regular fabrics – divided in to 5
              sections: Shirting, Suiting, Ethnic, Indo-western & Denim
            </p>
            <ul className="fabric_details_wrapper">
              <li>
                <span className="pink bold">Tresca</span> - new benchmark in
                premium fabric in the country from Arvind. Made out of premium
                Giza cotton with specialized processing, Tresca is a premium
                fabric that is the finest in contemporary international style
                and luxury.{" "}
              </li>
              <li>
                <span className="pink bold">Primante</span> - This
                limited-edition fabric collection is made using the best and
                most precious fabrics – Egyptian Giza Cotton, Superfine European
                Linens and Australian Merino Wool.
              </li>
            </ul>
            <p className="description semi_bold">
              Designer forecasts ensure the trendy and in-fashion fabric
              offerings at the store
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArvindStore;
