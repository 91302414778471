import React, { useEffect, useRef } from "react";
import "./home.scss";
import HeroTicker from "../../components/HomeComponents/HeroTicker/HeroTicker";
import HeroBanner from "../../components/HomeComponents/HeroBanner/HeroBanner";
import AboutTheBrand from "../../components/HomeComponents/AboutTheBrand/AboutTheBrand";
import KeyMilestones from "../../components/HomeComponents/KeyMilestones/KeyMilestones";
import NewFabrics from "../../components/HomeComponents/NewFabrics/NewFabrics";
import FranchiseCostInvestment from "../../components/HomeComponents/FranchiseCostInvestment/FranchiseCostInvestment";
import ArvindStore from "../../components/ArvindStore/ArvindStore";
import ExpansionPlan from "../../components/ExpansionPlan/ExpansionPlan";
import StoreRequirementAcc from "../../components/storeRequirementAcc/storeRequirementAcc";
import ApplyNowCta from "../../components/ApplyNowCta/ApplyNowCta";
import HomeApplyNow from "../../components/HomeComponents/HomeApplyNow/HomeApplyNow";

const Home = () => {
  const homeRef = useRef(null);
  const scrollRef = useRef(null);

  const scrollToDiv = (ref) => {
    if (ref.current) {
      const offsetTop = ref.current.offsetTop;
      window.scrollTo({
        top: offsetTop - 100, // Adjust this value to set the distance from the top
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (homeRef.current) {
      const offsetTop = homeRef.current.offsetTop;
      window.scrollTo({
        top: offsetTop - 100,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      <div ref={homeRef} className="homepage">
        <HeroBanner />
        <HeroTicker />
        <AboutTheBrand />
        <KeyMilestones />
        <ArvindStore />
        <NewFabrics />
        <FranchiseCostInvestment />
        <ExpansionPlan />
        <StoreRequirementAcc />
        <HomeApplyNow scrollId={scrollRef} />
        <ApplyNowCta scroll={() => scrollToDiv(scrollRef)} />
      </div>
    </>
  );
};

export default Home;
