import React, { useEffect } from "react";
import { thanYou } from "../../source";
import "./thankyou.scss";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

const GoogleAdConversion = () => {
  return (
    <>
      {process.env.REACT_APP_APP_MODE == "production" && (
        <Helmet>
          {/* Event snippet for Lead form- https://franchise.thearvindstore.com/ conversion page  */}
          <script>
            {` gtag('event', 'conversion', {'send_to': 'AW-11071017016/UBrWCOjgsNEZELigiZ8p'});`}
          </script>
        </Helmet>
      )}
    </>
  );
};

const Thankyoupage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <GoogleAdConversion />
      <section className="thank_sec1">
        <div className="row thank_row">
          <div className="col-lg-6 thank_col">
            <div className="content_wrapper">
              <h2 className="thank_heading">THANK YOU!</h2>
              <p className="thank_desc thank_desc_big">Thanks for applying.</p>
              <p className="thank_desc">
                Your message has been submitted. Will get back to you soon!
              </p>
              <div className="cta_wrapper">
                <button
                  onClick={() => navigate("/")}
                  className="common_cta"
                  width={1007}
                  height={943}
                >
                  GO BACK TO HOME
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 thank_col">
            <div className="img_wrapper">
              <img className="thank_img" src={thanYou} alt="thank you" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thankyoupage;
