import React from "react";
import "./herobanner.scss";
import { homeBannerData } from "../../../data/data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { bannerLeft, bannerRight } from "../../../source";
import { useWindowSize } from "react-use";

const HeroBanner = () => {
  const { width } = useWindowSize();

  const bannersList = homeBannerData.map((data) => (
    <SwiperSlide key={data.id} className="banner_slide">
      <img
        width={1920}
        height={690}
        className="banner_img"
        src={width > 600 ? data.dImg : data.mImg}
        alt={data.imgAlt}
        data-aos="fade"
      />
    </SwiperSlide>
  ));
  return (
    <>
      <section className="hero_banner">
        <Swiper
          className="banner_swiper"
          modules={[Navigation, Autoplay]}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{ prevEl: ".left_arrow", nextEl: ".right_arrow" }}
        >
          {bannersList}
          <div className="my_container navigation_wrapper">
            <img
              width={99}
              height={99}
              src={bannerLeft}
              alt="left navigation"
              className="nav_icon left_arrow"
              loading="lazy"
            />
            <img
              width={99}
              height={99}
              src={bannerRight}
              alt="right navigation"
              className="nav_icon right_arrow"
              loading="lazy"
            />
          </div>
        </Swiper>
      </section>
    </>
  );
};

export default HeroBanner;
