import React from "react";
import "./expansionPlan.scss";
import StoreBox from "../StoreBox/StoreBox";
import { expansionPlanData } from "../../data/data";

const ExpansionPlan = () => {
  return (
    <>
      <section className="expansion_plan_sec">
        <div className="my_container">
          <h2 className="heading" data-aos="fade-up">
            THE ARVIND STORE EXPANSION PLANS
          </h2>
          <StoreBox storeData={expansionPlanData} />
        </div>
      </section>
    </>
  );
};

export default ExpansionPlan;
