import React from "react";
import "./heroticker.scss";
import Counteritem from "../CounterItem/CounterItem";

const HeroTicker = () => {
  return (
    <div className="hero_ticker_wrapper">
      <div className="my_container">
        <section className="hero_ticker" data-aos="fade">
          <div className="ticker_flex">
            <div className="ticker_box ticker_box1">
              <div className="text_container">
                <p className="title">AREA</p>
                <div className="counter">
                  <Counteritem startCount={1000} endCount={1200} duration={5} />
                </div>
                <p className="unit">SQ. FT.</p>
              </div>
            </div>
            <div className="ticker_box ticker_box2">
              <div className="text_container">
                <p className="title">SIZE</p>
                <Counteritem
                  rupeeSign
                  lakh
                  plus
                  startCount={30}
                  endCount={80}
                  duration={4}
                />
                <p className="unit">INVESTMENT SIZE</p>
              </div>
            </div>
            <div className="ticker_box ticker_box3">
              <div className="text_container">
                <p className="title">OUTLETS</p>
                <Counteritem
                  startCount={100}
                  endCount={150}
                  duration={4}
                  plus
                />
                <p className="unit">NO. OF FRANCHISE OUTLETS</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HeroTicker;
