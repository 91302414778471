import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";
import { arvindlogo } from "../../source";
import { homeURL } from "../../helpers/paths";

const Header = () => {
  return (
    <>
      <header className="header_sec">
        <div className="my_container">
          <div className="logo_wrapper">
            <Link className="logo_link" to={homeURL}>
              <img className="logo_img" src={arvindlogo} alt="Arvind logo" />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
