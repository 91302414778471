import React from "react";
import "./applyNowCta.scss";
import { Link } from "react-router-dom";

const ApplyNowCta = ({ scroll, link }) => {
  return (
    <>
      <div className="applynow_cta">
        {link ? (
          <Link to={link ? link : () => false} className="applynow_text">
            APPLY NOW
          </Link>
        ) : (
          <Link to={() => false} onClick={scroll} className="applynow_text">
            APPLY NOW
          </Link>
        )}
      </div>
    </>
  );
};

export default ApplyNowCta;
