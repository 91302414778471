import React, { useEffect } from "react";
import "./notFound.scss";
import { homeURL } from "../../helpers/paths";
import { Link } from "react-router-dom";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="notfound_sec1" data-aos="fade">
        <div className="my_container">
          <div className="flex_box text-center">
            <h1 className="title">4<span className="black">0</span>4</h1>
            <h5 className="subtitle">Oops! That Page Can’t Be Found.</h5>
            <h6 className="desc">
              Sorry, the page you're looking for doesn't exist.
            </h6>
            <div className="cta_wrapper">
              <Link className="common_cta" to={homeURL}>
                Go back to Homepage
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
