import React, { useState, useRef, useEffect } from "react";
import "./storeRequirementAcc.scss";
import { storeReqAccData } from "../../data/data";
import { maroonDownArrow, whiteDownArrow } from "../../source";

const StoreRequirementAcc = () => {
  const [openStore, setOpenStore] = useState(0);
  const activeAccordionRef = useRef(null);

  const handleStoreClick = (index) => {
    setOpenStore((prevIndex) => (prevIndex === index ? null : index));
  };

  const scrollToActiveStore = () => {
    if (activeAccordionRef.current) {
      activeAccordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    scrollToActiveStore();
  }, [openStore]);

  return (
    <>
      <div className="storeReq_Acc" data-aos="fade-up">
        <div className="my_container">
          {storeReqAccData.map((item, index) => (
            <div
              className={`accordion-wrapper ${
                openStore === index ? "active" : ""
              }`}
              key={index}
              ref={openStore === index ? activeAccordionRef : null}
            >
              <div
                className={`accordion-title ${
                  openStore === index ? "active" : ""
                }`}
                onClick={() => handleStoreClick(index)}
              >
                <span>{item.title}</span>
                <img
                  src={openStore === index ? whiteDownArrow : maroonDownArrow}
                  className={`down_arrow ${
                    openStore === index ? "acc_open" : ""
                  }`}
                  alt="arrow"
                />
              </div>
              <div
                className={`accordion-item ${
                  openStore === index ? "active" : ""
                }`}
              >
                <table className="storereq_table">
                  <tbody>
                    {item.content.map((data, i) => (
                      <tr key={i}>
                        <td className="table_ques">{data.quest}</td>
                        <td className="table_answ">{data.ans}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StoreRequirementAcc;
