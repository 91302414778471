import React from "react";
import "./franchisecostinvestment.scss";

const FranchiseCostInvestment = () => {
  return (
    <div className="my_container">
      <section className="home_franchise">
        <h2 className="section_title franchise_title" data-aos="fade-up">
          THE ARVIND STORE FRANCHISE COST & INVESTMENT
        </h2>
        <div className="row franchise_row">
          <div className="col-md-4 p-0 mobile_box" data-aos="fade-up">
            <div className="franchise_box">
              <h5 className="fran_title">COMMENCED OPERATIONS</h5>
              <ul className="fran_list">
                <li className="list_item">
                  <p className="fran_heading">Operations Commenced On</p>
                  <p className="fran_value">2010</p>
                </li>
                <li className="list_item">
                  <p className="fran_heading">Operations Commenced On</p>
                  <p className="fran_value">2010</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 p-0 mobile_box" data-aos="fade-up">
            <div className="franchise_box">
              <h5 className="fran_title">FRANCHISE DETAILS</h5>
              <ul className="fran_list">
                <li className="list_item">
                  <p className="fran_heading">Investment</p>
                  <p className="fran_value">INR 80 Lacs - 1.2 Cr.</p>
                </li>
                <li className="list_item">
                  <p className="fran_heading">Franchise/Brand Fee</p>
                  <p className="fran_value">INR 1 Lac</p>
                </li>
                <li className="list_item">
                  <p className="fran_heading">Franchise Deposit</p>
                  <p className="fran_value">INR 5 Lacs</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 p-0 mobile_box" data-aos="fade-up">
            <div className="franchise_box">
              <h5 className="fran_title">DETAILS</h5>
              <ul className="fran_list">
                <li className="list_item">
                  <p className="fran_heading">
                    Anticipated percentage return on investment
                  </p>
                  <p className="fran_value">25%</p>
                </li>
                <li className="list_item">
                  <p className="fran_heading">
                    Likely pay back period of capital for a Unit Franchise
                  </p>
                  <p className="fran_value">3-5 Years</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FranchiseCostInvestment;
