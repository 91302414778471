import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "./counteritem.scss";

const Counteritem = ({
  startCount,
  endCount,
  plus,
  duration,
  rupeeSign,
  lakh,
}) => {
  return (
    <CountUp start={startCount} end={endCount} duration={duration}>
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start}>
          <>
            <div className="common_actual_count">
              <span>{rupeeSign ? "₹" : ""}</span>
              <span ref={countUpRef} />
              <span>{lakh ? " Lakh" : ""}</span>
              <span>{plus ? "+" : ""}</span>
            </div>
          </>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default Counteritem;
