import { images } from "../source";

export const homeBannerData = [
  {
    id: "banner 01",
    dImg: images.homeBanner01.image,
    mImg: images.homeBannerMb01.image,
    imgAlt: images.homeBanner01.alt,
  },
  {
    id: "banner 02",
    dImg: images.homeBanner02.image,
    mImg: images.homeBannerMb02.image,
    imgAlt: images.homeBanner02.alt,
  },
  {
    id: "banner 03",
    dImg: images.homeBanner03.image,
    mImg: images.homeBannerMb03.image,
    imgAlt: images.homeBanner03.alt,
  },
  {
    id: "banner 04",
    dImg: images.homeBanner04.image,
    mImg: images.homeBannerMb04.image,
    imgAlt: images.homeBanner04.alt,
  },
  {
    id: "banner 05",
    dImg: images.homeBanner05.image,
    mImg: images.homeBannerMb05.image,
    imgAlt: images.homeBanner05.alt,
  },
];

export const homeMilestoneData = [
  {
    id: "Milestone 01",
    year: "1931",
    desc: "Arvind Mills incorporated",
  },
  {
    id: "Milestone 02",
    year: "1934",
    desc: "Arvind becomes one of the most profitable mills of India",
  },
  {
    id: "Milestone 03",
    year: "1980",
    desc: "Arvind focuses on exports, launches India’s first denim brand –Flying Machine",
  },
  {
    id: "Milestone 04",
    year: "1988",
    desc: "Arvind enters Denim",
  },
  {
    id: "Milestone 05",
    year: "1991",
    desc: "Arvind becomes 3rd largest denim manufacturer in the world",
  },
  {
    id: "Milestone 06",
    year: "1993",
    desc: "Arvind launches Arrow",
  },
  {
    id: "Milestone 07",
    year: "1995",
    desc: "Arvind launches Ruf-n-Tuf & Megamart",
  },
  {
    id: "Milestone 08",
    year: "1997",
    desc: "Arvind sets up India’s largest integrated facility for shirting & bottoms",
  },
  {
    id: "Milestone 09",
    year: "2008",
    desc: "Launch of suiting fabric business",
  },
  {
    id: "Milestone 10",
    year: "2009",
    desc: "Launch of USPA, Denim chinos",
  },
  {
    id: "Milestone 11",
    year: "2010",
    desc: "Launch of The Arvind Store",
  },
  {
    id: "Milestone 12",
    year: "2013",
    desc: "Launch of Tresca –Premium Fabrics brand",
  },
];

export const newFabricsData = [
  {
    id: "fab_01",
    title: "ITALIAN FABRIC",
    content:
      "<ul><li>The most enchanting & Luxurious Collection in Primante Suiting ever</li><li>Superfine 150s 100% Merino Wool suiting</li><li>Designed, Crafted and Made in Italy</li><li>Hand - picked designs to match the Preference of our elite customers</li><li>To Top it all, there is new complimentary Primante Bag to be gifted with each Suit Length that a customer buys.</li></ul>",
    fabImg: images.italianFabric.image,
    fabAlt: images.italianFabric.alt,
  },
  {
    id: "fab_02",
    title: "PREMIUM Tailoring – Studio Arvind ",
    content:
      "<p class='large_title'>Light weight garments with attention to details</p><br /><ul><li>Touch of hand crafting for bringing in the premium factor</li><li>Premium trims that complement the fabrics</li><li>Specially to be recommended to customers purchasing Poly Wool & All Wool in 120’s and above counts</li><li>Specialized trims with patented technology for enhanced results on different types of fabrics</li><li>Special light weight & antistatic lining that has a very supple hand feel</li> <li>Special trims like DeoTags & Auto Adjusting hooks to add more value to the garment</li></ul>",
    fabImg: images.premiumtailoringstudioarvind.image,
    fabAlt: images.premiumtailoringstudioarvind.alt,
  },
];

export const termsData = [
  {
    tabName: "demo tab",
    data: [
      {
        title: "demo title",
        description: "demo description",
      },
    ],
  },
];

export const privacyData = [
  {
    heading: "demo heading",
    description: "demo description",
  },
];

export const storeData = [
  {
    id: 1,
    img: images.fabricStore.image,
    alt: images.fabricStore.alt,
    title: "FABRICS",
    description: "Shirting | Suiting | Ethnic",
  },
  {
    id: 2,
    img: images.customStore.image,
    alt: images.customStore.alt,
    title: "CUSTOM TAILORING",
    description: "Studio Arvind",
  },
  {
    id: 3,
    img: images.readyMadeStore.image,
    alt: images.readyMadeStore.alt,
    title: "READYMADE GARMENTS",
    description: "AD by Arvind",
  },
];

export const expansionPlanData = [
  {
    id: 1,
    img: images.andraTelengana.image,
    alt: images.andraTelengana.alt,
    title: "ANDRA PRADESH & TELENGANA",
    description: "Anaparthi | Proddatur | Tadepalli | Hyderabad",
  },
  {
    id: 2,
    img: images.rajasthanGujarat.image,
    alt: images.rajasthanGujarat.alt,
    title: "RAJASTHAN & GUJARAT",
    description: "Ajmer | Udaipur | Bikaner | Kota |  Ahmedabad |  Rajkot | Jamnagar | Veraval | Baroda | Bharuch | Surat | Navsari",
  },
  {
    id: 3,
    img: images.biharJharkhand.image,
    alt: images.biharJharkhand.alt,
    title: "BIHAR & JHARKHAND",
    description: "Buxar | Jamshedpur | Jamui | Ranchi",
  },
  {
    id: 4,
    img: images.madhayPrasesh.image,
    alt: images.madhayPrasesh.alt,
    title: "MADHYA PRADESH",
    description: "Bhopal | Bina | Chhatarpur | Indore | Naramdapuram Ujjain",
  },
  {
    id: 5,
    img: images.maharashtra.image,
    alt: images.maharashtra.alt,
    title: "MAHARASHTRA",
    description: "Mumbai | Nagpur | Nanded | Navi Mumbai | Pune",
  },
  {
    id: 6,
    img: images.uttarPradesh.image,
    alt: images.uttarPradesh.alt,
    title: "UTTAR PRADESH",
    description: "Agra | Aligarh | Azamgarh | Ballia | Banda | Bulandshahr | Deoria | Farrukhabad | Firozabad | Ghaziabad | Greater Noida | Haldwani | Haridwar | Jaunpur | Jhani | Kanpur Nagar | Lalitpur | Lucknow | Mathura | Meerut | Moradabad | Noida  | Raobertsganj | Roorkee | Saharanpur",
  },
  {
    id: 7,
    img: images.punjabHaryana.image,
    alt: images.punjabHaryana.alt,
    title: "PUNJAB & HARYANA",
    description: "Amritsar | Chandigarh",
  },
  {
    id: 8,
    img: images.tamilnaduKarnataka.image,
    alt: images.tamilnaduKarnataka.alt,
    title: "TAMIL NADU & KARNATAKA",
    description: "Chennai | Coimbatore | Salem | Tirupur",
  },
  {
    id: 9,
    img: images.kerelaTripuraOdisha.image,
    alt: images.kerelaTripuraOdisha.alt,
    title: "KERALA, TRIPURA & ODISHA",
    description: "Kozhikode (Calicut) | Agartala | Berhampur",
  },
];

export const storeReqAccData = [
  {
    id: 1,
    title: "THE ARVIND STORE FRANCHISE REQUIREMENTS",
    content: [
      {
        quest: "Type of property required for this franchise opportunity",
        ans: "Commercial",
      },
      {
        quest: "Floor area requirement",
        ans: "1200+ Sq.ft",
      }
    ],
  },
  {
    id: 2,
    title: "THE ARVIND STORE FRANCHISE TRAINING",
    content: [
      {
        quest: "Detailed operating manuals for franchisees",
        ans: "Yes",
      },
      {
        quest: "Franchisee training location",
        ans: "Ahmedabad",
      },
      {
        quest: "Is field assistance available for franchisee?",
        ans: "Yes",
      },
      {
        quest: "Expert guidance from Head Office to franchisee in opening the franchise",
        ans: "Yes",
      },
      {
        quest: "Current IT systems will be included in the franchise",
        ans: "Yes",
      },
    ],
  },
];
