import React from "react";
import "./storeBox.scss";

const StoreBox = ({ storeData }) => {
  return (
    <>
      <div className="row store_row">
        {storeData.map((store, i) => (
          <div
            className="col-md-6 col-lg-4 store_col"
            key={i}
            data-aos="fade-up"
          >
            <div className="store_box">
              <img className="store_img" src={store.img} alt={store.alt} />
              <div className="details_box">
                <h4 className="store_title">{store.title}</h4>
                <p className="store_details">{store.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default StoreBox;
