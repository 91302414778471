import React from "react";
import "./keymilestones.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { homeMilestoneData } from "../../../data/data";

const KeyMilestones = () => {
  const milestoneList = homeMilestoneData.map((data) => (
    <SwiperSlide key={data.id}>
      <article className="mil_box">
        <h3 className="mil_year">{data.year}</h3>
        <p
          className="mil_desc"
          dangerouslySetInnerHTML={{ __html: data.desc }}
        />
      </article>
    </SwiperSlide>
  ));
  return (
    <section className="home_milestone">
      <div className="my_container">
        <div className="content_box" data-aos="fade-up">
          <h2 className="section_title mil_title">KEY MILESTONES</h2>
          <Swiper
            className="mil_swiper"
            modules={[Autoplay]}
            slidesPerView={6}
            loop={true}
            spaceBetween={0}
            allowSlidePrev={false}
            allowTouchMove={false}
            speed={5000}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            // navigation={{
            //   prevEl: ".mil_left_arrow",
            //   nextEl: ".mil_right_arrow",
            // }}
            breakpoints={{
              0: {
                slidesPerView: 1.25,
              },
              600: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 6,
              },
            }}
          >
            {milestoneList}
            {/* <div className="navigation_wrapper">
              <img
                width={45}
                height={45}
                src={milLeft}
                alt="left navigation"
                className="nav_icon mil_left_arrow"
                loading="lazy"
              />
              <img
                width={45}
                height={45}
                src={milRight}
                alt="right navigation"
                className="nav_icon mil_right_arrow"
                loading="lazy"
              />
            </div> */}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default KeyMilestones;
