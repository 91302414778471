import React from "react";
import { Link } from "react-router-dom";
import {
  fbURL,
  homeURL,
  instaURL,
  linkedinURL,
  xURL,
  youtubeURL,
} from "../../helpers/paths";
import {
  arvindlogopng,
  facebookIcon,
  instagramIcon,
  linkedinIcon,
  xIcon,
  youtubeIcon,
} from "../../source";
import "./footer.scss";

const Footer = () => {
  const today = new Date();
  return (
    <>
      <section className="footer_sec1">
        <div className="my_container">
          <div className="row footer_main">
            <div className="col-md-5 col-lg-5">
              <div className="logo_wrapper">
                <Link to={homeURL}>
                  <img className="footer_logo" src={arvindlogopng} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="links_wrapper">
                <h6 className="link_heading">India Headquarters</h6>
                <p className="description">
                  Ahmedabad
                  <br />
                  Naroda Road, Near Chamunda Bridge, Ahmedabad – 380 025,
                  Gujarat, India.
                </p>
                <a href="tel:+917968268000" className="description">
                  Tel: +91-79-68268000
                </a>
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="links_wrapper">
                <h6 className="link_heading">Follow Us</h6>
                <div className="social_wrapper">
                  <a
                    className="social_box"
                    href={fbURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={27}
                      height={27}
                      src={facebookIcon}
                      alt="arvind facebook"
                      className="social_icon"
                      loading="lazy"
                    />
                  </a>
                  <a
                    className="social_box"
                    href={xURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={27}
                      height={27}
                      src={xIcon}
                      alt="arvind x"
                      className="social_icon"
                      loading="lazy"
                    />
                  </a>
                  <a
                    className="social_box"
                    href={instaURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={27}
                      height={27}
                      src={instagramIcon}
                      alt="arvind instagram"
                      className="social_icon"
                      loading="lazy"
                    />
                  </a>
                  <a
                    className="social_box"
                    href={linkedinURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={27}
                      height={27}
                      src={linkedinIcon}
                      alt="arvind linkedin"
                      className="social_icon"
                      loading="lazy"
                    />
                  </a>
                  <a
                    className="social_box"
                    href={youtubeURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={27}
                      height={27}
                      src={youtubeIcon}
                      alt="arvind youtube"
                      className="social_icon"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright_flex">
            <p className="copyright">
              <span>
                Arvind Fashioning Possibilities &copy; All Rights Reserved -{" "}
                {today.getFullYear()}
              </span>
            </p>
            <p className="copyright">
              Crafted by{" "}
              <a
                className="togglehead_link"
                href="https://www.togglehead.in/"
                target="_blank"
                rel="noreferrer"
              >
                Togglehead
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
