import React, { useState, useEffect, useRef } from "react";
import "./homeapplynow.scss";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import config from "../../../config/config";
import http from "../../../helpers/http";
import helper from "../../../helpers/helper";
import { thankyouURL } from "../../../helpers/paths";
import { selectdropIcon } from "../../../source";
const HomeApplyNow = ({ scrollId }) => {
  // =============== VARIABLE & STATES  ===========================

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [citySearchTerm, setCitySearchTerm] = useState("");
  const [stateSearchTerm, setStateSearchTerm] = useState("");
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const stateDropdownRef = useRef(null);
  const cityDropdownRef = useRef(null);
  const stateSelectionMade = useRef(false);
  const citySelectionMade = useRef(false);

  const navigate = useNavigate();

  let base_url = config.api_url + "/forms/contact";

  const joiOptions = config.joiOptions;
  // =============== FUNCTIONS  ===========================

  const validate = (data) => {
    let schemaObj = {
      name: Joi.string().trim().required().label("Name"),

      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label("Email"),
      // phone: Joi.number().required().label("Contact No."),
      phone: Joi.string()
        .length(10)
        .pattern(/^[0-9]+$/)
        .messages({ "string.pattern.base": `Phone No. must have 10 digits.` })
        .required()
        .label("Phone No."),

      state: Joi.string().trim().required().label("State"),
      city: Joi.string().trim().required().label("City"),
      pincode: Joi.number().required().label("Pincode"),
      investment: Joi.string().trim().required().label("This"),
      property: Joi.string().trim().required().label("This"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);

    let updatedValues = {
      ...values,
      state: selectedStateName,
      city: selectedCityName,
    };

    let errors = validate(updatedValues);
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      helper.scroll(helper.getFirstError(errors));
      return;
    }

    setLoading(true);

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, updatedValues);

    if (data) {
      setValues({});

      navigate(thankyouURL);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  // Handle state search input change
  const handleStateSearchChange = (e) => {
    setStateSearchTerm(e.target.value);
    setIsStateDropdownOpen(true);
    setIsCityDropdownOpen(false);
    stateSelectionMade.current = false;
  };

  // Handle city search input change
  const handleCitySearchChange = (e) => {
    setCitySearchTerm(e.target.value);
    setIsCityDropdownOpen(true);
    setIsStateDropdownOpen(false);
    citySelectionMade.current = false;
  };

  // Handle state input focus
  const handleStateInputFocus = () => {
    setStateSearchTerm(""); // Reset state search term
    setCitySearchTerm("");
    setIsStateDropdownOpen(true); // Open dropdown
    setIsCityDropdownOpen(false);
  };

  // Handle city input focus
  const handleCityInputFocus = () => {
    setCitySearchTerm(""); // Reset city search term
    setIsCityDropdownOpen(true); // Open dropdown
    setIsStateDropdownOpen(false);
  };

  // Handle state option click
  const handleStateOptionClick = (item) => {
    // If the same state is clicked again, reset cities
    if (item.id === selectedStateId) {
      setCities([]); // Clear cities
      setSelectedStateName("");
      setSelectedStateId(null);
      setStateSearchTerm("");
    } else {
      setStateSearchTerm(item.name);
      setIsStateDropdownOpen(false);
      setSelectedStateId(item.id);
      setSelectedStateName(item.name);
      setSelectedCityName(""); // Clear selected city when state changes
      fetchCities(item.id); // Fetch cities based on the selected state ID
    }
  };

  // Handle city option click
  const handleCityOptionClick = (item) => {
    setCitySearchTerm(item.name);
    setIsCityDropdownOpen(false);
    setSelectedCityName(item.name);
  };

  // Handle clicks outside dropdowns
  const handleClickOutside = (e) => {
    if (
      stateDropdownRef.current &&
      !stateDropdownRef.current.contains(e.target) &&
      cityDropdownRef.current &&
      !cityDropdownRef.current.contains(e.target)
    ) {
      setIsStateDropdownOpen(false);
      setIsCityDropdownOpen(false);
      stateSelectionMade.current = false;
      citySelectionMade.current = false;
    }
  };

  // Handle Enter and Tab key press on state input
  const handleStateKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === "Tab") && !stateSelectionMade.current) {
      e.preventDefault();
      if (filteredStates.length > 0) {
        handleStateOptionClick(filteredStates[0]);
        stateSelectionMade.current = true;
      }
    }
  };

  // Handle Enter and Tab key press on city input
  const handleCityKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === "Tab") && !citySelectionMade.current) {
      e.preventDefault();
      if (filteredCities.length > 0) {
        handleCityOptionClick(filteredCities[0]);
        citySelectionMade.current = true;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filter states and cities based on search terms
  const filteredStates = states.filter((state) =>
    state.name.toLowerCase().includes(stateSearchTerm.toLowerCase())
  );

  const filteredCities = cities.filter((city) =>
    city.name.toLowerCase().includes(citySearchTerm.toLowerCase())
  );
  // =============== HTML RENDERING  ===========================
  // =============== DATA FETCHING  ===========================

  // const handleStateChange = async (e) => {
  //   const state_id = e.target[e.target.selectedIndex].getAttribute("data-id");

  //   fetchCities(state_id);
  // };
  const fetchStates = async (country_id) => {
    const data = await http.get(
      `${config.api_url}/general/states/${country_id}`
    );
    // console.log(data);
    if (data) {
      setStates(data.data);
    }
  };
  const fetchCities = async (state_id) => {
    const data = await http.get(`${config.api_url}/general/cities/${state_id}`);
    // console.log(data);
    if (data) {
      setCities(data.data);
    }
  };
  // =============== SIDE EFFECTS  ===========================

  useEffect(() => {
    fetchStates(101);
  }, []);

  return (
    <div className="my_container">
      <section
        className="home_applyform"
        ref={scrollId}
        id="apply-form"
        data-aos="fade-up"
      >
        <h4 className="section_title apply_title">
          INTERESTED IN THE ARVIND STORE FRANCHISE? APPLY HERE
        </h4>
        <form className="form_container" onSubmit={handleSubmit} noValidate>
          <div className="row form_row">
            <div className="col-md-6 form_column">
              <div className="form_field_wrapper">
                <div className="field_title">Name*</div>
                <input
                  type="text"
                  className="form_input"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                <p className="error_message" id="name_error">
                  {formError.name}
                </p>
              </div>
            </div>
            <div className="col-lg-6 form_column">
              <div className="form_field_wrapper">
                <div className="field_title">Email*</div>
                <input
                  type="email"
                  className="form_input"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <p className="error_message" id="email_error">
                  {formError.email}
                </p>
              </div>
            </div>
            <div className="col-lg-6 form_column">
              <div className="form_field_wrapper">
                <div className="field_title">Phone No.*</div>
                <input
                  type="tel"
                  className="form_input"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                <p className="error_message" id="phone_error">
                  {formError.phone}
                </p>
              </div>
            </div>
            <div className="col-md-6 form_column">
              <div
                ref={stateDropdownRef}
                style={{ position: "relative" }}
                className="form_field_wrapper"
              >
                <div className="field_title">State*</div>
                {/*
                  <div className="arvind-dropdown">
                   <input
                    type="text"
                    placeholder="Search state"
                    value={searchState}
                    onChange={handleSearchChange}
                  />
                  <select
                    name="state"
                    value={values.state}
                    onChange={(e) => {
                      handleChange(e);
                      handleStateChange(e);
                    }}
                  >
                    <option disabled selected value hidden></option>
                    {filteredStates?.map((item) => {
                      return (
                        <option value={item.name} data-id={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                <div className="dropdown_wrapper">
                  <input
                    type="text"
                    className="form_input"
                    value={stateSearchTerm}
                    onChange={handleStateSearchChange}
                    onFocus={handleStateInputFocus}
                    onKeyDown={handleStateKeyDown}
                  />
                  {isStateDropdownOpen && (
                    <div className="select_dropdown">
                      {filteredStates.map((item) => (
                        <div
                          key={item.id}
                          style={{ padding: "8px", cursor: "pointer" }}
                          onClick={() => handleStateOptionClick(item)}
                          className="dropdown_option"
                        >
                          {item.name}
                        </div>
                      ))}
                    </div>
                  )}
                  <img
                    width={10}
                    height={8}
                    src={selectdropIcon}
                    alt="arrow"
                    className={`drop_arrow ${isStateDropdownOpen && "active"}`}
                  />
                </div>
                <p className="error_message" id="state_error">
                  {formError.state}
                </p>
              </div>
            </div>
            <div className="col-md-6 form_column">
              <div
                ref={cityDropdownRef}
                className="form_field_wrapper"
                style={{ position: "relative" }}
              >
                <div className="field_title">City*</div>
                {/*<div className="arvind-dropdown">
                   <select
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  >
                    <option disabled selected value hidden></option>
                    {cities?.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </div> */}
                <div className="dropdown_wrapper">
                  <input
                    type="text"
                    className="form_input"
                    value={citySearchTerm}
                    onChange={handleCitySearchChange}
                    onFocus={handleCityInputFocus}
                    onKeyDown={handleCityKeyDown}
                  />
                  {isCityDropdownOpen && (
                    <div className="select_dropdown">
                      {filteredCities.map((item) => (
                        <div
                          key={item.id}
                          style={{ padding: "8px", cursor: "pointer" }}
                          onClick={() => handleCityOptionClick(item)}
                          className="dropdown_option"
                        >
                          {item.name}
                        </div>
                      ))}
                    </div>
                  )}
                  <img
                    width={10}
                    height={8}
                    src={selectdropIcon}
                    alt="arrow"
                    className={`drop_arrow ${isCityDropdownOpen && "active"}`}
                  />
                </div>
                <p className="error_message" id="city_error">
                  {formError.city}
                </p>
              </div>
            </div>
            <div className="col-md-6 form_column">
              <div className="form_field_wrapper">
                <div className="field_title">Pincode*</div>
                <input
                  type="tel"
                  className="form_input"
                  name="pincode"
                  value={values.pincode}
                  onChange={handleChange}
                />
                <p className="error_message" id="pincode_error">
                  {formError.pincode}
                </p>
              </div>
            </div>
            <div className="col-md-6 form_column">
              <div className="form_field_wrapper">
                <div className="field_title">
                  Are you ready to invest 80 Lacs+ ?*
                </div>
                <input
                  type="text"
                  className="form_input"
                  name="investment"
                  value={values.investment}
                  onChange={handleChange}
                />
                <p className="error_message" id="investment_error">
                  {formError.investment}
                </p>
              </div>
            </div>
            <div className="col-md-6 form_column">
              <div className="form_field_wrapper">
                <div className="field_title">
                  Do you have any property on lease or ownership (1200 sq.ft.+)
                  ?*
                </div>
                <input
                  type="text"
                  className="form_input"
                  name="property"
                  value={values.property}
                  onChange={handleChange}
                />
                <p className="error_message" id="property_error">
                  {formError.property}
                </p>
              </div>
            </div>
          </div>
          <div className="cta_wrapper">
            <button className="common_cta">
              {loading ? "Loading.." : "Submit"}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default HomeApplyNow;
