import React from "react";
import "./aboutthebrand.scss";
import { images } from "../../../source";
import { useWindowSize } from "react-use";

const AboutTheBrand = () => {
  const { width } = useWindowSize();
  return (
    <>
      <div className="my_container">
        <section className="the_brand">
          <div className="row m-0">
            <div className="col-md-6 p-0" data-aos="fade-up">
              <div className="brand_box1">
                <div className="container container1">
                  <h2 className="section_title brand_title">ABOUT THE BRAND</h2>
                  <h3 className="brand_subtitle">
                    Arvind, a pioneer in Textile and Garments
                  </h3>
                  {width < 768 && (
                    <img
                      width={837}
                      height={918}
                      src={images.aboutTheBrand.image}
                      alt={images.aboutTheBrand.alt}
                      className="the_brand_img"
                      loading="lazy"
                    />
                  )}
                  <p className="brand_desc">
                    Since 1931, Arvind has been the leader in fabric market,
                    both national and international. Arvind is now creating a
                    revolutionary fashion experience across the country with the
                    best of fabrics from its textile division, leading
                    apparelled menswear brands, and bespoke fashion solutions.
                    Join us and become a part of the Arvind family.
                  </p>
                </div>
                <div className="container container2">
                  <h2 className="section_title brand_title">
                    ARVIND'S FIRST IN INDIA
                  </h2>
                  <ul className="brand_list">
                    <li className="list_item">First to Introduce Denim</li>
                    <li className="list_item">
                      First to build largest and fully integrated YD facility
                      under one roof
                    </li>
                    <li className="list_item">
                      First Denim brand in India: Flying Machine
                    </li>
                    <li className="list_item">
                      First International Brand: Arrow
                    </li>
                    <li className="list_item">
                      First Exclusive Brand Outlet: Arrow Store
                    </li>
                    <li className="list_item">
                      First Mass Market Brand : Ruf n Tuf
                    </li>
                    <li className="list_item">
                      First Factory Outlet : Megamart
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {width >= 768 && (
              <div className="col-md-6 p-0" data-aos="fade-up">
                <div className="brand_box2">
                  <img
                    width={837}
                    height={918}
                    src={images.aboutTheBrand.image}
                    alt={images.aboutTheBrand.alt}
                    className="the_brand_img"
                    loading="lazy"
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutTheBrand;
