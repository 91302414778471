import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routing from "./Routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetaDecorator from "./components/MetaDecorator/MetaDecorator";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/pagination";

// AOS styles
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
      // disable: window.innerWidth < 1279,
      // once: true,
    });
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Welcome to Arvind"
        metaDesc="Welcome to Arvind"
        canonicalLink={window.location}
      />
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Routing />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
