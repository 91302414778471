import React, { useState, useRef, useEffect } from "react";
import "./newfabrics.scss";
import { newFabricsData } from "../../../data/data";
import { fabuparrow } from "../../../source";
import { useWindowSize } from "react-use";

const NewFabrics = () => {
  const { width } = useWindowSize();
  const [openIndex, setOpenIndex] = useState(0);
  const activeAccordionRef = useRef(null);

  const handleItemClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const scrollToActiveAccordion = () => {
    if (activeAccordionRef.current) {
      activeAccordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    scrollToActiveAccordion();
  }, [openIndex]);

  return (
    <div className="my_container">
      <section className="new_fabrics">
        <h2 className="section_title fab_title" data-aos="fade-up">
          THE ARVIND STORE: NEW FABRICS
        </h2>
        <div className="row fab_accordion_box">
          <div className="col-lg-6 container1" data-aos="fade-up">
            {newFabricsData.map((item, index) => (
              <div
                className={`accordion_box ${
                  openIndex === index ? "active" : ""
                }`}
                key={index}
                ref={openIndex === index ? activeAccordionRef : null}
              >
                <div
                  className="accordion-title"
                  onClick={() => handleItemClick(index)}
                >
                  <span>{item.title}</span>
                  <img
                    className={`plus-minus ${
                      openIndex === index ? "active" : ""
                    }`}
                    src={fabuparrow}
                    alt="nav"
                    loading="lazy"
                  />
                </div>
                <div
                  className={`accordion-item ${
                    openIndex === index ? "active" : ""
                  }`}
                >
                  {width < 992 && (
                    <img
                      width={796}
                      height={519}
                      src={item.fabImg}
                      alt={item.alt}
                      className="fab_img"
                      loading="lazy"
                    />
                  )}
                  <div
                    className="accordion-content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              </div>
            ))}
          </div>
          {width >= 992 && (
            <div className="col-lg-6 container2" data-aos="fade-up">
              <img
                width={796}
                height={519}
                src={newFabricsData[openIndex ? openIndex : 0].fabImg}
                alt={newFabricsData[openIndex ? openIndex : 0].alt}
                className="fab_img"
                loading="lazy"
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default NewFabrics;
