export { default as whitearrow } from "./images/general/white-arrow.svg";
export { default as circle } from "./images/general/circle.svg";
export { default as wctaarrow } from "./images/general/Polygon-3.svg";
export { default as downarrow } from "./images/general/Polygon-1.svg";
export { default as dummyvideo } from "./video/demo-video.mp4";
export { default as mailicon } from "./images/general/mail-icon.svg";
export { default as phonecallicon } from "./images/general/phone-call-icon.svg";
export { default as fbwhite } from "./images/general/fb-white.svg";
export { default as instawhite } from "./images/general/insta-white.svg";
export { default as ytwhite } from "./images/general/yt-white.svg";
export { default as linkedinwhite } from "./images/general/linkedin-white.svg";
export { default as footerlocation } from "./images/general/footer-location-icon.svg";
export { default as footermail } from "./images/general/footer-mail-icon.svg";
export { default as footercall } from "./images/general/footer-phone-call-icon.svg";
export { default as activearrow } from "./images/general/hamburger-arrow.svg";
export { default as whiteDownArrow } from "./images/general/white_down_arrow.svg";
export { default as maroonDownArrow } from "./images/general/maroon_down_arrow.svg";

// HOMEPAGE IMAGES
export { default as bannerLeft } from "./images/home/banner-left.svg";
export { default as bannerRight } from "./images/home/banner-right.svg";
export { default as milLeft } from "./images/home/mil-left.svg";
export { default as milRight } from "./images/home/mil-right.svg";
export { default as fabuparrow } from "./images/home/newfabrics/fab-up-arrow.svg";
export { default as selectdropIcon } from "./images/home/select-drop-icon.svg";

// HEADER IMAGES
export { default as arvindlogo } from "./images/general/arvind_logo_white_back.svg";

// FOOTER IMAGES
export { default as arvindlogopng } from "./images/general/arvind_logo_black.svg";
export { default as xIcon } from "./images/general/x-square.png";
export { default as youtubeIcon } from "./images/general/youtube-square.png";
export { default as facebookIcon } from "./images/general/facebook-square.png";
export { default as instagramIcon } from "./images/general/instagram-square.png";
export { default as linkedinIcon } from "./images/general/linkedin-square.png";

// THANKYOU IMAGES
export { default as thanYou } from "./images/thankyou/404.jpg";

export let images = {
  homeBanner01: {
    image: require("./images/home/banner/banner-01.jpg"),
    alt: "Arvind Store",
  },
  homeBannerMb01: {
    image: require("./images/home/banner/mb-banner-01.jpg"),
    alt: "Arvind Store",
  },
  homeBanner02: {
    image: require("./images/home/banner/banner-02.jpg"),
    alt: "Arvind Store",
  },
  homeBannerMb02: {
    image: require("./images/home/banner/mb-banner-02.jpg"),
    alt: "Arvind Store",
  },
  homeBanner03: {
    image: require("./images/home/banner/banner-03.jpg"),
    alt: "Arvind Store",
  },
  homeBannerMb03: {
    image: require("./images/home/banner/mb-banner-03.jpg"),
    alt: "Arvind Store",
  },
  homeBanner04: {
    image: require("./images/home/banner/banner-04.jpg"),
    alt: "Arvind Store",
  },
  homeBannerMb04: {
    image: require("./images/home/banner/mb-banner-04.jpg"),
    alt: "Arvind Store",
  },
  homeBanner05: {
    image: require("./images/home/banner/banner-05.jpg"),
    alt: "Arvind Store",
  },
  homeBannerMb05: {
    image: require("./images/home/banner/mb-banner-05.jpg"),
    alt: "Arvind Store",
  },
  aboutTheBrand: {
    image: require("./images/home/about-the-brand.jpg"),
    alt: "About The Brand",
  },
  italianFabric: {
    image: require("./images/home/newfabrics/italian-fabric.jpg"),
    alt: "Italian Fabrics",
  },
  premiumtailoringstudioarvind: {
    image: require("./images/home/newfabrics/premium-tailoring-studio-arvind.png"),
    alt: "premium tailoring studio arvind",
  },
  fabricStore: {
    image: require("./images/home/FABRICS_store.jpg"),
    alt: "FABRICS",
  },
  customStore: {
    image: require("./images/home/CUSTOM_TAILORING_store.jpg"),
    alt: "CUSTOM TAILORING",
  },
  readyMadeStore: {
    image: require("./images/home/READYMADE_GARMENTS_store.jpg"),
    alt: "READYMADE GARMENTS",
  },
  andraTelengana: {
    image: require("./images/home/ANDRA_PRADESH&TELENGANA.jpg"),
    alt: "ANDRA_PRADESH & TELENGANA",
  },
  rajasthanGujarat: {
    image: require("./images/home/RAJASTHAN&GUJARAT.jpg"),
    alt: "RAJASTHAN & GUJARAT",
  },
  biharJharkhand: {
    image: require("./images/home/BIHAR&JHARKHAND.jpg"),
    alt: "BIHAR & JHARKHAND",
  },
  madhayPrasesh: {
    image: require("./images/home/MADHYA_PRADESH.jpg"),
    alt: "MADHYA PRADESH",
  },
  maharashtra: {
    image: require("./images/home/MAHARASHTRA.jpg"),
    alt: "MAHARASHTRA",
  },
  uttarPradesh: {
    image: require("./images/home/UTTARPRADESH.jpg"),
    alt: "UTTAR PRADESH",
  },
  punjabHaryana: {
    image: require("./images/home/PUNJAB&HARYANA.jpg"),
    alt: "UTTAR PRADESH",
  },
  tamilnaduKarnataka: {
    image: require("./images/home/TAMILNADU&KARNATAKA.jpg"),
    alt: "UTTAR PRADESH",
  },
  kerelaTripuraOdisha: {
    image: require("./images/home/KERALA_TRIPURA&ODISHA.jpg"),
    alt: "UTTAR PRADESH",
  },
};
